.plus {
  margin-left: 1rem;
  color: var(--bg-color);
  cursor: pointer;

  &:hover {
    color: var(--bg-color-darker);
    font-size: 3.5rem;
  }

  transition: all 0.4s;
}

.overlayText {
  text-shadow: 2px 2px rgba(0, 0, 0, 0.5);
}

.videoPlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.5;

  &:hover {
    opacity: 1;
  }

  transition: opacity 0.4s;
}

.videoPlayActive {
  position: inherit;
  top: inherit;
  left: inherit;
  transform: inherit;
  opacity: inherit;
}

.bgStatic {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}