$small: 300px;
$medium: 960px;

.form {
  overflow: scroll;
  padding-top: 6rem;
  padding-bottom: 2rem;
  height: 400px;
}

.fixedHeader {
  position: fixed;
  padding: 1rem;
  top: calc(2px + 1rem);
  // left: calc(1rem + 2px);
  width: calc(100% - 2rem - 4px);
  z-index: 2;
  // font-family: "Abhaya Libre", serif;
  // font-size: 4rem;
  text-align: center;
  margin: 0 auto;
  background: white;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.2);

  h2 {
    margin: 0;
    overflow: hidden;
  }
}

.overlayClickable {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.input {
  color: darkgray;
}

.empty {
  text-align: center;
}

.blogImageSmall {
  height: 10.4rem;
  border-radius: 0.4rem;

  @media (max-width: 1200px) {
    height: 9.25rem;
  }

  @media (max-width: 768px) {
    height: 13rem;
  }
}

.screen {
  opacity: 0;
  background: #00000030;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    opacity: 1;
  }

  transition: all 0.4s;
}

.titleContainer {
  min-height: 4.4rem;
}

.title {
  line-height: 1.7rem;
  font-size: 1.3rem;
  cursor: pointer;

  &:hover {
    color: var(--bg-color)
  }

  transition: color 0.3s;
}

.text {
  min-height: 7rem;
}

.recentBlog {
  cursor: pointer;

  &:hover {
    // background: rgba(0, 0, 0, 0.1);
    transform: scale(1.1);
    // border: solid 1px rgba(0, 0, 0, 0.1) ;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
  }

  transition: all 0.3s;
}