.form {
  overflow: scroll;
  padding-top: 6rem;
  padding-bottom: 2rem;
  height: 400px;
}

.fixedHeader {
  position: fixed;
  padding: 1rem;
  top: calc(2px + 1rem);
  width: calc(100% - 2rem - 4px);
  z-index: 2;
  text-align: center;
  margin: 0 auto;
  background: white;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.2);

  h2 {
    margin: 0;
    overflow: hidden;
  }
}

.overlayClickable {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.input {
  color: darkgray;
}

.empty {
  text-align: center;
}