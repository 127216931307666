.message {
  text-align: center;

  &.error > * {
    color: darkred;
  }

  &.success > * {
    color: forestgreen;
  }
}

.forgot {
  text-transform: uppercase;
  margin-top: 1rem;
  color: var(--text-color-sc);
  &:hover {
    transform: scale(1.1);
    color: var(--text-color-hover);
  }
  transition: all 0.3s;
  cursor: pointer;
}
