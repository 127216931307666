.root {
  background: white;
}

h2 {
  text-align: center;
  padding: 0.5rem;
}

.listItem {
  background: grey;

  &:nth-child(odd) {
    background: darkgrey;
  }

  padding: 0.5rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 1px white;

  &:last-child {
    border: none;
  }

  cursor: pointer;

  &:hover {
    background: rgb(97, 96, 96);
  }

  img {
    width: 44px;
    height: 44px;
    margin-right: 1rem;
  }

  transition: all 0.3s
}