.formContainer {
  // z-index: 0;
}

.form {
  overflow: scroll;
  padding-top: 6rem;
  padding-bottom: 2rem;
  height: 400px;
}

.fixedHeader {
  position: fixed;
  padding: 1rem;
  top: calc(2px + 1rem);
  // left: calc(1rem + 2px);
  width: calc(100% - 2rem - 4px);
  z-index: 2;
  // font-family: "Abhaya Libre", serif;
  // font-size: 4rem;
  text-align: center;
  margin: 0 auto;
  background: white;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.2);
  h2 {
    margin: 0;
    overflow: hidden;
  }
}

.input {
  color: darkgray;
}

.empty {
  text-align: center;
}
