.root {
    ul {
        display: flex;
        align-items: center;
    }
}

.pageEllipsis {
    margin-left: 0.3rem;
    margin-right: 0.3rem;
    font-size: 2.5rem;
    color: var(--bg-color);

    >div {
        top: -0.75rem;
        position: relative;
    }
}