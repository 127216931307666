td,
th {
  border: solid 1px black;
}

th,
td {
  text-align: center !important;
  overflow: hidden;
}

th {
  background: #eee;
  padding: 0.5rem;
}

tr td:first-child {
  max-width: 17rem;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.listHeader {
  margin-top: 2rem;
  font-size: 1.3rem;
}