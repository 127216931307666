.root {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.button {
  color: white;
  font-weight: 700;
  background: var(--bg-color);
  margin-bottom: 2px;
  -moz-user-select: none;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 4px;
  display: inline-block;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  padding: 8px 16px;
  text-align: center;
  transition: all 0.2s ease-in-out 0s;
  vertical-align: middle;
  white-space: nowrap;
  &:hover {
    background: var(--bg-color-darker);
  }
  &.cancel {
    background: darkgrey;
    margin-left: 1rem;
    &:hover {
      background: grey;
    }
  }
}
