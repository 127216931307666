.root {
  position: absolute;
  background: rgba(255, 255, 255, 0.7);
  height: 4rem;
  padding: 1rem;
  top: 0px;
  left: 0;
  width: 100%;
  transition: background 1s;
}

.transparent {
  background: rgba(255, 255, 255, 0);
}
