.root {
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-size: 1rem;
  color: white;
}

.modalScreen {
  z-index: 0;
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
}

.modalContainer {
  position: fixed;
  padding: 1rem;
  overflow: hidden;
  width: 1200px;
  max-width: 90vw;
  min-width: 24rem;
  max-height: 90vh;
  top: 50vh;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 0;
}

.modal {
  position: relative;
  overflow-y: scroll;
  background: white;
  width: 100%;
  height: 100%;
  border: 2px solid rgba(0, 0, 0, 0.5);
}

.closeButtonBackground {
  background: white !important;
}

.closeColor {

  &:before,
  &:after {
    background: var(--bg-color);
  }
}