/*==========================
	Custom css
==========================*/
.App {
  position: relative;
}

/* BigShow Owl Slider */
.slider-title {
  font-size: 70px;
  line-height: 70px;
  font-family: "Abhaya Libre", serif;
  color: var(--bg-color-sc);
  text-shadow: 0 0 8px white;
  /*color:#5E5E5E;*/
}

.slide-item-img img {
  -webkit-mask-image: url(../images/main-slider/slide-bg.png);
  mask-image: url(../images/main-slider/slide-bg.png);
  -webkit-mask-position: center center;
  mask-position: center center;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-size: auto 100%;
  mask-size: auto 100%;
}

.slider-title span {
  display: block;
  position: relative;
}

.slide-content-area p {
  max-width: 600px;
  font-size: 18px;
  margin-bottom: 40px;
  font-weight: 400;
  color: #000;
  line-height: 30px;
}

.slide-content-area a {
  margin-right: 15px;
}

.owl-slider .carousel-control-prev,
.owl-slider .carousel-control-next {
  /* background: transparent; */
  padding: 0;
  border: solid 2px var(--bg-color);
  margin: 0 10px !important;
  border-radius: 25px;
  color: var(--bg-color);
  display: inline-block;
  height: 50px;
  width: 50px;
  line-height: 54px;
  text-align: center;
  top: 50%;
  opacity: 1;
  transform: translateY(-50%);
}

.owl-slider .carousel-control-prev:hover,
.owl-slider .carousel-control-next:hover {
  background: var(--bg-color);
  opacity: 0.5;
}

.slide-item {
  position: relative;
}

.slide-item .slide-content {
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  max-width: 50%;
}

.slide-item-img img {
  object-fit: cover;
}

.slide-content-box {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transition: all 1s ease 0.5s;
  -webkit-transition: all 1s ease 0.5s;
  -o-transition: all 1s ease 0.5s;
  -moz-transition: all 1s ease 0.5s;
  display: flex;
  height: 100%;
  align-items: center;
}

.slide-content-box p {
  max-width: 85%;
}

/* Theme Btn */
/* .kids-btn{
	background: rgb(255,152,22);
	background: -moz-linear-gradient(top, rgba(255,152,22,1) 0%, rgba(255,133,0,1) 100%);
	background: -webkit-linear-gradient(top, rgba(255,152,22,1) 0%,rgba(255,133,0,1) 100%);
	background: linear-gradient(to bottom, rgba(255,152,22,1) 0%,rgba(255,133,0,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff9816', endColorstr='#ff8500',GradientType=0 );
	box-shadow: inset -3px -3px 0 0 rgba(0,0,0,0.15);
	padding:15px 18px 15px 18px;
	font-size:21px;
	font-weight:400;
}
.kids-btn i{
	width:43px;
	height:43px;
	border-radius:43px;
	background: rgb(231,114,0);
	background: -moz-linear-gradient(top, rgba(231,114,0,1) 0%, rgba(255,156,33,1) 100%);
	background: -webkit-linear-gradient(top, rgba(231,114,0,1) 0%,rgba(255,156,33,1) 100%);
	background: linear-gradient(to bottom, rgba(231,114,0,1) 0%,rgba(255,156,33,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e77200', endColorstr='#ff9c21',GradientType=0 );
	line-height:43px;
	box-shadow: 0px -1px 0px 0 rgba(0,0,0,0.20),inset 0px -2px 0px 0 rgba(255,255,255,0.4);
	margin-right: 15px;
} */

.section-head h2 {
  font-size: 50px;
  line-height: 60px;
  margin-bottom: 5px;
}

.section-head p {
  font-size: 18px;
  font-weight: 300;
  line-height: 30px;
}

/* service iconbox */
.sr-iconbox .dlab-tilte {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 0;
  line-height: 30px;
}

.sr-iconbox .icon-lg img {
  height: auto;
}

.sr-iconbox .icon-lg {
  width: auto;
}

.dots-none .owl-dots {
  display: none;
}

.about-box p {
  font-size: 18px;
}

.about-box .icon-bx-wraper {
  margin-bottom: 15px;
}

.about-box .icon-bx-wraper .icon-lg i {
  font-size: 60px;
  line-height: 75px;
}

.about-box .icon-bx-wraper .icon-lg {
  width: 60px;
  margin-right: 20px;
}

.about-box .icon-bx-wraper .dlab-tilte {
  margin-top: 0;
  font-size: 30px;
  color: var(--text-color);
  margin-bottom: 5px;
  line-height: 38px;
  font-family: "Abhaya Libre", serif;
  font-weight: 900;
}

.about-box .icon-bx-wraper p {
  font-size: 18px;
  font-weight: 300;
  line-height: 30px;
}

.text-blue {
  color: #68bde5;
}

.text-green {
  color: #6dce63;
}

.text-orange {
  color: #ffa63d;
}

.about-content {
  position: relative;
  z-index: 1;
  padding: 200px 0 150px 0;
}

.about-text h2 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  line-height: 80px;
  color: #fff;
  margin-bottom: 20px;
}

.about-content:before,
.about-content:after {
  content: "";
  height: 80px;
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 2;
  background-repeat: no-repeat;
  background-size: 100%;
}

.about-content:before {
  background-image: url(../images/line-bg2.png);
  bottom: -1px;
  background-position: bottom;
}

.about-content:after {
  background-image: url(../images/line-bg1.png);
  top: 0;
}

.text-yellow {
  color: #fff000;
}

.frame-box:after {
  content: "";
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  background-image: url(../images/image-frame.png);
  background-size: 100% 100%;
  z-index: 1;
}

[class*="box-frame"] img {
  -webkit-mask-image: url(../images/team/team-bg1.png);
  mask-image: url(../images/team/team-bg1.png);
  -webkit-mask-position: center center;
  mask-position: center center;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-size: 100%;
  mask-size: 100%;
}

.team-frame1 img {
  -webkit-mask-image: url(../images/team/team-bg1.png);
  mask-image: url(../images/team/team-bg1.png);
}

.team-frame2 img {
  -webkit-mask-image: url(../images/team/team-bg2.png);
  mask-image: url(../images/team/team-bg2.png);
}

.team-frame3 img {
  -webkit-mask-image: url(../images/team/team-bg3.png);
  mask-image: url(../images/team/team-bg3.png);
}

.team-frame4 img {
  -webkit-mask-image: url(../images/team/team-bg4.png);
  mask-image: url(../images/team/team-bg4.png);
}

.sprite-nav .owl-nav {
  margin-top: 40px;
}

.sprite-nav .owl-next,
.sprite-nav .owl-prev {
  width: 50px;
  height: 50px;
  background-image: url(../images/icons/arrow.png);
  background-size: cover;
  transition: all 0.5s;
  -moz-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  background-color: transparent;
}

.sprite-nav .owl-next {
  background-position: -105px 0px;
}

.sprite-nav .owl-prev {
  background-position: -53px 0;
}

.sprite-nav .owl-next:hover {
  background-position: right;
}

.sprite-nav .owl-prev:hover {
  background-position: 0 0;
}

.team-box .overlay-bx {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.team-box ul {
  margin-bottom: 0;
  margin-top: 10px;
}

.team-box ul li a {
  width: 26px;
  height: 26px;
  color: #fff;
  display: block;
  border-radius: 30px;
  font-size: 12px;
  line-height: 26px;
  background: var(--bg-color);
}

.team-box ul li a:hover {
  background: var(--bg-color-ho);
}

.team-box .dlab-thum:after {
  background-color: rgba(255, 255, 255, 0);
  content: "";
  z-index: 1;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.5s;
  -moz-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
}

.team-box:hover .dlab-thum:after {
  background-color: rgba(255, 255, 255, 0.7);
}

.team-box .team-title {
  text-transform: uppercase;
  margin-bottom: 2px;
  color: #000;
}

.team-box .team-info {
  font-size: 13px;
}

/* Blog  */
.blog-carousel .blog-grid {
  margin-bottom: 0;
}

.blog-grid .dlab-info {
  padding-top: 30px;
}

.blog-grid .dlab-post-title .post-title {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 21px;
  line-height: 35px;
}

.blog-grid .dlab-post-text {
  margin-bottom: 15px;
}

.blog-grid .dlab-post-text p {
  font-size: 16px;
  line-height: 28px;
  font-weight: 400;
  color: #686868;
}

.blog-grid .btn-link {
  color: #191918;
  font-size: 18px;
  font-style: italic;
}

.blog-grid .btn-link:hover {
  color: var(--text-color);
}

.blog-carousel .owl-next,
.blog-carousel .owl-prev {
  opacity: 0;
}

.blog-carousel:hover .owl-next,
.blog-carousel:hover .owl-prev {
  margin: 0 -100px !important;
  opacity: 1;
}

.blog-carousel .class-item {
  margin-bottom: 30px;
  box-shadow: 0 0 0 8px rgba(255, 255, 255, 1),
    0 0px 30px 0px rgba(0, 0, 0, 0.2);
}

.widget_ftabout .footer-logo {
  margin-bottom: 20px;
  margin-top: -30px;
}

.widget_ftabout p {
  font-size: 18px;
  line-height: 32px;
  color: #000;
  /* text-transform: capitalize; */
  font-weight: 300;
}

.blog-carousel.sprite-nav .owl-nav {
  margin: 0;
}

/* Client Box */
.client-carousel {
  padding: 0 100px;
}

.client-box {
  background-color: #f7fbfb;
  border-radius: 30px;
  padding: 20px;
  display: flex;
  box-sizing: border-box;
  margin: 15px 15px;
  position: relative;
  z-index: 1;
}

.client-box:after {
  background-color: #f7fbfb;
  border-radius: 30px;
  content: "";
  position: absolute;
  width: calc(100% + 30px);
  height: calc(100% + 30px);
  left: -15px;
  z-index: -2;
  top: -15px;
}

.client-box:before {
  background-color: #f7fbfb;
  border-radius: 30px;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  left: 0;
  top: 0;
  border: 2px dashed #000;
}

.client-box .testimonial-pic {
  border: 0;
  margin-bottom: 10px;
}

.client-box .testimonial-detail {
  padding: 5px;
  text-align: center;
  max-width: 200px;
  width: 200px;
  min-width: 200px;
}

.client-box .testimonial-name {
  color: var(--text-color-sc);
}

.client-box .testimonial-text {
  padding-left: 80px;
}

.client-box .testimonial-text p {
  color: #191918;
  font-size: 18px;
  font-weight: 400;
  line-height: 32px;
}

.client-box .testimonial-text:after {
  content: "\f10d";
  font-size: 50px;
  position: absolute;
  left: 0;
  color: #c4d0d3;
  font-family: FontAwesome;
  top: 10px;
}

/* Teacher Info */
.teacher-info {
}

.teacher-content .teacher-coures {
  color: #191918;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  margin-bottom: 20px;
  display: block;
  text-transform: uppercase;
}

.teacher-content p {
  font-size: 18px;
  line-height: 32px;
  color: #000;
  text-transform: capitalize;
  font-weight: 300;
}

.teacher-content .teacher-title {
  font-family: "Abhaya Libre", serif;
  font-weight: 700;
  font-size: 40px;
  margin-bottom: 0;
}

.teacher-content ul {
  margin-bottom: 0;
}

.teacher-content ul li a {
  width: 40px;
  height: 40px;
  color: #fff;
  display: block;
  border-radius: 0 10px 10px 10px;
  font-size: 16px;
  line-height: 40px;
  background: var(--bg-color);
  text-align: center;
}

.teacher-content ul li a:hover {
  background: var(--bg-color-ho);
}

.teacher-media img {
  width: 100%;
  border-radius: 15px;
}

.video-play .popup-youtube {
  height: 135px;
  width: 135px;
  border-radius: 50%;
  line-height: 135px;
  font-size: 36px;
  display: block;
  text-align: center;
  color: #000;
  background-color: #fff000;
  margin: auto;
}

.video-play .popup-youtube:hover {
  color: #000;
  background-color: #fff000;
}

/* Progress Section ======= */
.progress-section .progress-bx .progress {
  margin-right: 50px;
}

.progress-section .progress-bx .count-box {
  float: right;
  font-size: 18px;
  font-weight: 500;
  color: #000;
  line-height: 10px;
}

.progress-bx .bg-blue {
  background-color: #68bde5;
}

.progress-bx .bg-green {
  background-color: #6dce63;
}

.progress-bx .bg-orange {
  background-color: #ffa63d;
}

.progress-section .progress-bx .progress {
  height: 12px;
  border-radius: 4px;
  overflow: unset;
}

.progress-section .progress-bx {
  margin-bottom: 30px;
}

.progress-section .progress-bx .title {
  font-size: 15px;
}

.progress-section .progress-bx .progress-bar {
  position: relative;
  border-radius: 4px;
}

.progress-section .progress-bx .progress-bar::after {
  content: "";
  height: 24px;
  width: 24px;
  background-color: #fff;
  right: -2px;
  position: absolute;
  border-radius: 50px;
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.1);
}

.contact-box .form-control {
  min-width: 20rem;
  background-color: #c4eafb;
  border: 2px solid #c4eafb;
  height: 60px;
  border-radius: 6px;
  padding: 20px;
  font-size: 1.5rem;
  line-height: 2.25rem;
  font-weight: 300;
  /* color: var(--text-color-grey); */
}

.contact-box textarea.form-control {
  height: 180px;
}

.banner-map > div {
  border-radius: 15px;
  vertical-align: middle;
}

.masonry-gallery .frame-box:after {
  background-image: none;
  border: 2px dashed var(--text-color);
  border-radius: 20px;
  box-shadow: inset 0 0 0px 7px #fff, 0 0 0px 7px #fff;
  box-sizing: border-box;
  transform: scale(1.03);
}

.filter-style1 .filters {
  padding: 0;
}

.filter-style1 .filters .btn:hover,
.filter-style1 .filters .btn {
  background-color: transparent;
}

.filter-style1 .filters .btn a {
  margin: 0;
  color: #000;
  font-family: "Abhaya Libre", serifb;
  text-transform: capitalize;
  font-size: 20px;
  padding: 0 15px;
  font-weight: 900;
}

.filter-style1 .filters .active.btn a {
  color: var(--text-color);
}

/* Class Item */
.class-item {
  margin-bottom: 50px;
}

.class-item .class-media {
  position: relative;
  text-align: cenetr;
}

.class-item .class-media p {
  margin: 0;
  display: inline-block;
  font-size: 14px;
  background: #ff4;
  position: absolute;
  bottom: 0;
  padding: 5px 20px;
  color: #000;
  font-weight: 500;
  border-radius: 10px 10px 0px 0px;
  width: calc(100% - 40px);
  left: 20px;
  text-align: center;
}

.class-info p {
  font-size: 16px;
  line-height: 28px;
  color: #686868;
  margin-bottom: 15px;
}

.class-info h4 {
  margin-bottom: 5px;
}

.class-item .class-media p span {
  color: #000;
  font-weight: 700;
}

.class-info a {
  color: var(--text-color);
}

.class-info {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.class-item {
  background: #fff;
  border: 2px dashed var(--text-color);
  border-radius: 10px;
  padding: 8px;
  box-shadow: 0 0 0 8px rgba(255, 255, 255, 1),
    0 10px 50px 0px rgba(61, 68, 240, 0.1);
}

.schedule {
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.schedule li {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 0 0 33.33%;
  width: 33.33%;
  border-radius: 8px;
  border: 2px solid #fff;
  padding: 0.5rem 0.3rem;
}

.schedule li span {
  display: block;
  font-weight: 600;
  color: #fff;
  font-size: 12px;
}

.schedule li span:last-child {
  font-size: 17px;
}

.classes-details {
  margin-bottom: 4rem;
}

.classes-details .class-info {
  padding: 0;
}

.classes-details .class-media + .class-info {
  padding-top: 20px;
}

.classes-details .post-title {
  margin-bottom: 5px;
  font-family: "Abhaya Libre", serif;
}

.classes-details .post-title a {
  color: #000;
}

.details-tbl {
  margin-bottom: 50px;
}

.class-details {
  margin: 0px;
  padding: 0;
}

.class-details li {
  overflow: hidden;
  list-style: none;
  padding: 15px 20px;
  background-color: var(--bg-color-sc);
  border-radius: 4px;
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 10px;
  transition: all 0.5s;
  -moz-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
}

.class-details li:hover {
  transform: scale(1.05);
  -moz-transform: scale(1.05);
  -ms-transform: scale(1.05);
  -webkit-transform: scale(1.05);
  -o-transform: scale(1.05);
}

.class-details li:nth-child(2n + 1) {
  background-color: var(--bg-color);
}

.class-details .name {
  float: left;
  width: 50%;
  padding-left: 25px;
  position: relative;
}

.class-details .info {
  float: left;
  width: 50%;
  font-style: italic;
}

.class-details .name i {
  font-size: 24px;
  position: absolute;
  left: -5px;
  top: -2px;
}

.about-media img {
  -webkit-mask-image: url(../images/about/about-bg.png);
  mask-image: url(../images/about/about-bg.png);
  -webkit-mask-position: center center;
  mask-position: center center;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  width: 100%;
}

.FAQ-box {
  background-color: #fff04c;
  padding: 25px 30px;
  border-radius: 30px;
  box-shadow: 4px 4px 0 0 #ffdf00;
  margin-bottom: 40px;
}

.FAQ-box .card,
.FAQ-box .card-header {
  background-color: transparent;
  border: 0;
  padding: 0;
}

.FAQ-box .card-header button {
  display: block;
  width: 100%;
  text-align: left;
  background-color: transparent;
  color: #2f2b00;
  font-size: 16px;
  font-weight: 600;
  border: none;
  border-bottom: 2px solid rgba(255, 255, 255, 0.8);
  padding: 18px 0px 18px 50px;
  position: relative;
}

.FAQ-box .card:last-child .card-header button {
  border-bottom: 0;
}

.FAQ-box .card-header button:after {
  content: "\f056";
  font-family: FontAwesome;
  position: absolute;
  font-size: 30px;
  color: #000;
  top: 8px;
  text-shadow: 4px 1px 0 #fff;
  left: 10px;
  transition: all 0.5s;
  -moz-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
}

.FAQ-box .card-header button.collapsed:after {
  content: "\f055";
}

.FAQ-box .card-body {
  color: #000;
  font-size: 16px;
  padding: 20px 0px;
  line-height: 30px;
  border-bottom: 2px solid rgba(255, 255, 255, 0.9);
}

.about-iconbx.icon-bx-wraper .dlab-tilte {
  font-size: 20px;
  color: #000;
  margin-bottom: 10px;
}

.about-iconbx.icon-bx-wraper .icon-content p {
  font-size: 15px;
  line-height: 28px;
  font-weight: 400;
  color: #686868;
}

.about-iconbx.icon-bx-wraper [class*="icon-bx"] i {
  font-size: 35px;
}

.about-iconbx.icon-bx-wraper [class*="icon-bx"] {
  position: relative;
}

.about-iconbx.icon-bx-wraper [class*="icon-bx"]:after {
  content: "";
  background-image: url(../images/icons/rounded-bg.png);
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-size: 100%;
}

.childern-box {
  position: relative;
  margin-top: 60px;
}

.childern-box .childern-1 {
  position: absolute;
  top: 0;
  width: 100%;
}

.childern-box .childern-2 {
  animation: jump1 0.5s linear alternate infinite;
  position: relative;
}

.childern-box .childern-1 {
  animation: jump 0.5s linear alternate infinite;
}

/* @keyframes jump {
  0%   {transform: translate3d(0,0,0) scale3d(1,1,1);}
  40%  {transform: translate3d(0,50%,0) scale3d(1,1,1);}
  100% {transform: translate3d(0,100%,0) scale3d(1,1,1);}
} */
@keyframes jump {
  0% {
    top: -10px;
  }

  30% {
    top: -30px;
  }

  100% {
    top: -50px;
  }
}

@keyframes jump1 {
  0% {
    top: -50px;
  }

  30% {
    top: -30px;
  }

  100% {
    top: -10px;
  }
}

@-webkit-keyframes jump {
  0% {
    top: -10px;
  }

  30% {
    top: -30px;
  }

  100% {
    top: -50px;
  }
}

@-webkit-keyframes jump1 {
  0% {
    top: -50px;
  }

  30% {
    top: -30px;
  }

  100% {
    top: -10px;
  }
}

.about-kids {
  margin-bottom: -100px;
}

.event-box {
  background-color: #fff04c;
  border-radius: 15px;
  overflow: hidden;
  margin-bottom: 30px;
}

.event-info {
  text-align: center;
  position: relative;
  padding: 90px 30px 30px 30px;
}

.event-meta ul {
  padding: 0;
  list-style: none;
  margin: 0;
}

.event-box .post-date {
  background: #3d56f0;
  color: #fff;
  width: 130px;
  height: 130px;
  border-radius: 130px;
  position: absolute;
  padding: 25px 0;
  top: -65px;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
}

.event-box .post-date strong {
  font-size: 60px;
  line-height: 60px;
  display: block;
}

.event-box .post-date span {
  display: block;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 2px;
}

.event-box .post-date:after {
  content: "";
  width: 120px;
  height: 120px;
  border-radius: 120px;
  top: 5px;
  left: 5px;
  position: absolute;
  border: 2px dashed #fff;
}

.event-box .event-meta {
  margin-bottom: 15px;
}

.event-box .dlab-post-text {
  margin-bottom: 0;
}

.event-box .dlab-post-text p {
  font-weight: 500;
  font-size: 15px;
  color: #000;
  line-height: 30px;
}

.event-box .dlab-post-title .post-title {
  margin-top: 0;
  margin-bottom: 5px;
  font-weight: 500;
  font-size: 30px;
}

.event-box .dlab-post-title .post-title a {
  color: #000;
}

.event-box .post-author {
  color: #3d56f0;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 15px;
}

/* Error Page ======= */
.error-page .dz_error {
  font-family: Poppins;
  font-size: 260px;
  color: #1c1c1c;
  font-weight: 700;
  line-height: 260px;
}

.error-page .error-head {
  font-size: 40px;
  font-weight: 400;
  color: #484646;
  line-height: 55px;
  max-width: 760px;
  display: inline-block;
}

.error-page .input-group .btn {
  border-radius: 50px;
}

.error-page .input-group .btn:hover {
  background-color: transparent;
}

.error-page .input-group .btn img {
  width: 21px;
}

.error-page .input-group .form-control,
.error-page .input-group .form-control:active,
.error-page .input-group .form-control:focus {
  border: 0;
  background-color: transparent;
  padding: 25px 30px;
  font-weight: 500;
}

.error-page .input-group {
  border: 2px solid #eceef2;
  border-radius: 40px;
}

.error-page .subscribe-form {
  max-width: 560px;
  width: 560px;
  display: inline-block;
}

.split-box {
  position: relative;
  overflow: hidden;
}

.split-box > div {
  /* opacity: 0; */
  -webkit-transition: 0s 0.8s;
  -o-transition: 0s 0.8s;
  transition: 0s 0.8s;
}

.split-box:before,
.split-box:after {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.split-box:before {
  background-color: #3d56f0;
  z-index: 1;
  -webkit-transform: translateX(-101%);
  -ms-transform: translateX(-101%);
  transform: translateX(-101%);
}

.split-box:after {
  background-color: #fff04c;
  z-index: 2;
  -webkit-transform: translateX(-101%);
  -ms-transform: translateX(-101%);
  transform: translateX(-101%);
}

@-webkit-keyframes split-box-animation {
  0% {
    -webkit-transform: translateX(-101%);
    transform: translateX(-101%);
  }

  50% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  100% {
    -webkit-transform: translateX(101%);
    transform: translateX(101%);
  }
}

@keyframes split-box-animation {
  0% {
    -webkit-transform: translateX(-101%);
    transform: translateX(-101%);
  }

  50% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  100% {
    -webkit-transform: translateX(101%);
    transform: translateX(101%);
  }
}

.split-box.split-active > div {
  opacity: 1;
}

.split-box.split-active:after {
  -webkit-animation: split-box-animation 1.2s 0.2s
    cubic-bezier(1, 0, 0.54, 0.99) forwards;
  animation: split-box-animation 1.2s 0.2s cubic-bezier(1, 0, 0.54, 0.99)
    forwards;
}

.split-box.split-active:before {
  -webkit-animation: split-box-animation 1.5s cubic-bezier(0.86, 0, 0.07, 1)
    forwards;
  animation: split-box-animation 1.5s cubic-bezier(0.86, 0, 0.07, 1) forwards;
}

.coming-soon {
  /* height: 100vh; */
  position: relative;
  /* display: flex;
  justify-content: center; */
}

.countdown .date div strong {
  font-size: 20px;
  font-weight: 400;
  color: #1b1b1b;
  text-transform: uppercase;
  box-shadow: inset 0px -4px 0 var(--bg-color);
  line-height: 18px;
  display: inline-block;
}

.countdown .date div {
  display: block;
  line-height: 30px;
}

.countdown .date {
  display: inline-block;
  text-align: center;
  width: 130px;
}

.countdown .date .time {
  font-size: 70px;
  font-family: Poppins;
  color: #3d56f0;
  font-weight: 700;
  display: block;
  text-shadow: 3px 3px 0 #fff04c;
}

.countdown-box {
  display: flex;
  line-height: 70px;
  align-items: flex-start;
  top: 4rem;
  position: relative;
  /* padding: 50px 50px 0; */
}

.countdown-box .countdown {
  margin-left: auto;
}

.coming-head h2 {
  line-height: 162px;
  font-size: 162px;
  display: inline;
  position: relative;
  z-index: 0;
}

.coming-head h2 strong {
  color: var(--bg-color-sc);
}

.coming-head h2 span {
  font-size: 40px;
  color: #1b1b1b;
  position: absolute;
  right: 26px;
  top: -4rem;
  line-height: 40px;
}

.coming-head {
  display: block;
  max-width: 1170px;
  text-align: center;
  margin: 0 auto;
  margin-top: 9rem;
}

.coming-head .input-group .btn {
  border-radius: 40px;
  height: 40px;
  padding: 0;
  width: 40px;
  margin: 5px;
  color: #000;
}

.coming-head .subscribe-form {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
}

.coming-head .subscribe-form .form-control:hover,
.coming-head .subscribe-form .form-control:active,
.coming-head .subscribe-form .form-control:focus,
.coming-head .subscribe-form .form-control {
  border: 0;
  border-radius: 40px !important;
}

.coming-head .input-group {
  background: #fff04c;
  border: 1px solid #fff04c;
  border-radius: 50px;
}

input:-internal-autofill-previewed,
input:-internal-autofill-selected,
textarea:-internal-autofill-previewed,
textarea:-internal-autofill-selected,
select:-internal-autofill-previewed,
select:-internal-autofill-selected {
  background-color: #fff;
}

/* Blog Post */
.blog-post.style-2 {
  background-color: #fff04c;
  border-radius: 10px;
  overflow: hidden;
  /* box-shadow:6px 6px 40px 0 rgba(62,57,0,0.1); */
}

.blog-post.style-2 .dlab-info {
  padding: 30px;
}

.blog-post.style-2 .dlab-info .dlab-post-text p {
  font-size: 15px;
  font-weight: 500;
}

/* Light Gallery */
.lg-backdrop {
  background-color: #fff000;
}

.lg-actions .lg-next,
.lg-actions .lg-prev,
.lg-sub-html,
.lg-toolbar {
  background-color: #3d56f0;
}

.lg-outer .lg-toogle-thumb,
.lg-actions .lg-next,
.lg-actions .lg-prev,
.lg-toolbar .lg-icon {
  color: #fff;
}

.lg-outer .lg-toogle-thumb,
.lg-outer .lg-thumb-outer {
  background-color: #ed5ab0;
}

.lg-outer .lg-thumb-item.active,
.lg-outer .lg-thumb-item:hover {
  border-color: #fff000;
}

.lg-outer .lg-thumb-item {
  border: 4px solid #fff;
}

.about-overlay-box {
  position: absolute;
  height: 100%;
  width: 100%;
  background: #000;
  top: 0;
  left: 0;
  opacity: 0.5;
  z-index: -1;
}

.widget:last-child {
  margin-bottom: 30px;
}

@media screen and (max-width: 1200px) {
  .slider-title {
    font-size: 45px;
    margin-bottom: 5px;
    line-height: 55px;
  }

  .slide-content-area p {
    margin-bottom: 20px;
  }

  .slide-content-area {
    padding-left: 50px;
  }

  .blog-carousel.owl-btn-center-lr .owl-next,
  .blog-carousel.owl-btn-center-lr .owl-prev {
    position: unset;
    opacity: 1;
    transform: translateY(0);
    -o-transform: translateY(0);
    -moz-transform: translateY(0);
    -webkit-transform: translateY(0);
    margin: 0 5px !important;
  }

  .blog-carousel.sprite-nav .owl-nav {
    margin-top: 30px;
  }

  .blog-carousel:hover .owl-next,
  .blog-carousel:hover .owl-prev {
    margin: 0 5px !important;
  }

  .schedule {
    display: flex;
  }

  .schedule li {
    flex: 100%;
    max-width: 100%;
    text-align: center;
  }
}

@media screen and (max-width: 991px) {
  .slide-item-img img {
    height: 600px;
  }

  .client-carousel {
    padding: 0;
  }

  .error-page .dz_error {
    font-size: 200px;
    line-height: 200px;
  }

  .error-page .error-head {
    font-size: 30px;
    line-height: 45px;
  }

  .countdown .date {
    width: 80px;
  }

  .countdown .date .time {
    font-size: 50px;
  }

  .countdown .date div strong {
    font-size: 16px;
  }

  .about-content {
    padding: 100px 0 60px 0;
  }

  .event-box .dlab-post-title .post-title {
    font-size: 24px;
  }

  .event-box .post-date:after {
    width: 90px;
    height: 90px;
  }

  .event-box .post-date {
    width: 100px;
    height: 100px;
  }

  .event-box .post-date strong {
    font-size: 38px;
    line-height: 38px;
  }

  .event-box .post-date span {
    font-size: 14px;
  }

  .event-info {
    padding: 60px 30px 30px 30px;
  }
}

@media screen and (max-width: 767px) {
  .error-page .dz_error {
    font-size: 120px;
    line-height: 120px;
  }

  .error-page .error-head {
    font-size: 20px;
    line-height: 34px;
  }

  .error-page .subscribe-form {
    width: 100%;
  }

  .slider-title {
    font-size: 26px;
    margin-bottom: 5px;
    line-height: 35px;
  }

  .slider-title span:after {
    content: none;
  }

  .slider-title span {
    padding: 0;
  }

  .slide-content-area {
    margin-right: 12px;
    padding: 10px 20px;
    font-size: 13px;
    text-align: left;
  }

  .slide-content-area p {
    margin-bottom: 10px;
    padding-right: 35px;
  }

  .slide-content-area a {
    margin-right: 10px;
    padding: 9px 20px;
    font-size: 14px;
  }

  .section-head h2 {
    font-size: 28px;
    line-height: 40px;
    margin-bottom: 0;
  }

  .section-head p {
    font-size: 16px;
    line-height: 28px;
  }

  .client-box .testimonial-detail {
    max-width: 100%;
    width: 100%;
    min-width: 100%;
  }

  .client-box {
    flex-direction: column;
  }

  .client-box .testimonial-text {
    padding: 0;
    text-align: center;
  }

  .sprite-nav .owl-next,
  .sprite-nav .owl-prev {
    width: 40px;
    height: 40px;
  }

  .sprite-nav .owl-prev {
    background-position: -42px 0;
  }

  .sprite-nav .owl-next {
    background-position: -84px 0px;
  }

  .sprite-nav .owl-nav {
    margin-top: 30px;
  }

  .widget_ftabout p,
  .about-box .icon-bx-wraper p,
  .client-box .testimonial-text p,
  .blog-grid .dlab-post-text p {
    font-size: 15px;
  }

  .client-box .testimonial-text:after {
    content: none;
  }

  .testimonial-pic {
    width: 70px;
    height: 70px;
  }

  .about-content {
    padding: 120px 0 80px 0;
  }

  .about-box {
    padding-bottom: 0;
  }

  .about-box .icon-bx-wraper {
    margin-bottom: 20px;
  }

  .sr-iconbox .dlab-tilte {
    font-size: 16px;
    font-weight: 500;
    line-height: 28px;
  }

  /* .logo-header img {
    height: 40px;
  } */
  .dlab-topbar-left ul li,
  .dlab-topbar-right ul li {
    font-size: 13px;
  }

  .footer-top {
    padding: 100px 0 20px;
  }

  .blog-post {
    margin-bottom: 20px;
  }

  .comment-respond {
    padding: 20px 0;
  }

  .dlab-bnr-inr::before {
    height: 22px;
    background-size: 100%;
  }

  .dlab-bnr-inr::after {
    height: 17px;
    background-size: 100%;
  }

  .countdown-box {
    display: block;
  }

  .coming-head {
    padding: 0 15px;
  }

  .slide-item-img img {
    height: 450px;
  }
}

@media screen and (max-width: 576px) {
  .footer-bottom .text-left,
  .footer-bottom .list-inline {
    text-align: center !important;
  }

  .footer-top {
    padding-bottom: 10px;
  }

  .footer-bottom {
    padding: 20px 0;
  }

  .sr-iconbox br {
    display: inline-block;
  }

  .pagination-lg .pagination > li > a,
  .pagination-lg
    .pagination
    > li
    > span
    .pagination-lg
    .pagination
    > .previous
    > a,
  .pagination-lg .pagination > .next > a {
    font-size: 15px;
    padding: 8px 15px;
  }

  .class-media.m-b50 {
    margin-bottom: 20px;
  }

  .coming-head h2 {
    line-height: 100px;
    font-size: 100px;
  }

  .coming-head h2 span {
    font-size: 24px;
    line-height: 24px;
  }

  .countdown-box {
    padding: 50px 10px 0;
  }

  .owl-slider {
    text-align: center;
  }

  .owl-slider .carousel-control-prev,
  .owl-slider .carousel-control-next {
    margin: 0 5px !important;
    position: unset;
  }

  .about-content {
    padding: 50px 0 20px 0;
  }

  .footer-top:after {
    height: 30px;
  }

  .widget_ftabout .footer-logo {
    margin-top: 0;
  }

  .footer-top {
    padding: 60px 0 20px;
  }

  .about-content .btn {
    margin-top: 15px;
  }

  .site-filters {
    margin-bottom: 30px;
  }

  .childern-box {
    display: none;
  }

  .about-kids {
    margin-bottom: 0;
  }

  .footer-bottom span {
    margin-bottom: 10px;
    display: block;
  }
}
