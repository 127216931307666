.root {
  margin: 1rem 0.3rem;
  max-width: 300px;
  padding: 0.7rem;
  font-size: 1.5rem;
  border-radius: 0.6rem;
  color: #eee;
  background: #329fcd;
  transition: background 0.3s;

  &:hover {
    background: darken(#329fcd, 10%);
  }
}