.root {
  transition: opacity 1.2s;
}

.icon {
  width: 100px;
  text-align: center;
  display: inline-block;
  margin-bottom: 20px;
}

.hide {
  opacity: 0;
}

.fadeIn {
  opacity: 1;
}

.plus {
  margin-left: 1rem;
}

.js-scroll {
  transition: all 1s;
}

.slideup {
  position: relative;
  opacity: 0;
  top: 5rem;

  &.js-scrolled {
    opacity: 1;
    top: 0;
  }
}

.enrollBtn {
  display: block;
  text-align: center;
  max-width: 15rem;
  width: 35%;
  cursor: pointer;
  margin: 0 auto;
  transition: all .4s;

  &:hover {
    transform: rotate(10deg)
  }
}

.edBanner {
  background-image: url("https://firebasestorage.googleapis.com/v0/b/freedom-learning-academy.appspot.com/o/images%2Fgeneral%2Fcustom-background%2Fbg2.jpg?alt=media&token=e20b3fc3-e765-488b-8cfb-257573983fdb");
}