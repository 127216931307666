.plus {
  // margin-left: 1rem;
  color: var(--bg-color);
  cursor: pointer;

  &:hover {
    color: var(--bg-color-darker);
    font-size: 3.5rem;
  }

  transition: all 0.4s;
}