.root {
  font-size: 0.75rem;

  i {
    margin-left: 1rem;

    &:first-child {
      margin-left: 0;
    }
  }
}

.widget_services .list-2 li a.active {
  font-weight: 700;
}

.separator {
  margin-top: 20px;
  padding: 20px 0;
  border-top: 2px solid var(--separator);
  color: rgba(0, 0, 0, 0.4);
}

.footerList {
  display: flex !important;
  flex-wrap: wrap;
}

.about {
  font-size: 18px;
  line-height: 32px;
  color: #000;
  font-weight: 300;
}

.info {
  font-style: italic;
  font-size: 0.75rem;
  text-align: center;
}

.bottom {
  padding: 40px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}