.root {
  position: fixed;
  padding: 1rem;
  // overflow: hidden;
  // background: #f7f96b;
  width: 100vh;
  max-width: 40%;
  height: 0;
  min-height: 7rem;
  top: 0;
  left: 50%;
  transform: translateX(-50%) translateY(-100%);
  z-index: 100;
  opacity: 0;
  transition: all 0.6s ease-out;
  &.tablet,
  &.tabletpro,
  &.phone {
    max-width: 60%;
  }
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 1.5rem;
  position: relative;
  overflow-y: scroll;
  background: rgb(255, 255, 0);
  border-radius: 1rem;
  width: 100%;
  height: auto;
  padding: 1rem;
  border: 2px solid rgba(0, 0, 0, 0.5);
  &.tablet,
  &.tabletpro,
  &.phone {
    font-size: 1rem;
  }
}

.show {
  transform: translateX(-50%) translateY(0%);
  opacity: 1;
}

.close {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  width: 2rem;
  height: 2rem;
  border-radius: 1rem;
  background: rgb(255, 255, 0);
  border: 2px solid grey;
  z-index: 1;
  &::after {
    content: "X";
    position: absolute;
    top: -0.2rem;
    right: 0.4rem;
    font-size: 1.5rem;
    color: #4d4d4d;
  }
  &:hover {
    background: rgb(200, 200, 0);
  }
}
