.message {
  text-align: center;

  &.error>* {
    color: darkred;
  }

  &.success>* {
    color: forestgreen;
  }
}

.select {
  height: auto !important;
  font-size: 1.2rem !important;
}

section {
  margin-top: 4rem;
  border-top: solid 1px grey;
  padding-top: 2rem;

  &:first-of-type {
    margin-top: 0;
    border-top: none;
  }
}