.root {
  margin-top: 4rem;
}

.item {
  max-height: 20rem;
  display: flex !important;
  align-items: center;
}

.owner {
  border: solid 1px darkgreen;
}

.overlayClickable {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.input {
  color: darkgray;
}

.empty {
  text-align: center;
}

.outline {
  background-image: url(../../../images/team/team-bg-outline.png);
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.role {
  font-style: italic;
  position: relative;
  top: -0.5rem;
  color: var(--bg-color);
}

.deleteButton {
  margin-top: 1rem;
}

.navBtns {
  text-align: center;
}