.root {
  position: relative;
}

.deleted {
  color: gray !important;

  &:before {
    color: #ccc;
    background-color: #fbf0f0 !important;
  }
}

.watermark {
  position: absolute;
  top: 0;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
  color: rgba(255, 0, 0, 0.1);
  font-size: 4rem;
  font-weight: 900;
  text-align: center;
}

.removeWatermark {
  display: none;
}

.text {
  width: 100%;
}

.textContainer {
  width: 100%;
  padding: 15px;
  position: relative;
  font-size: 15px;
  font-weight: 400;
  padding-left: 80px;
}

.iconButton {
  cursor: pointer;
  font-size: 3.5rem !important;
  color: darkgrey;
  position: absolute;
  z-index: 2;
}

.minus {
  top: 0.3rem;
  right: 0.6rem;

  &:hover {
    color: grey;
    font-size: 4rem !important;
    top: 0.5rem;
    right: 0.9rem;
  }
}

.check {
  bottom: 0.3rem;
  right: 0.6rem;

  &:hover {
    color: green;
    font-size: 4rem !important;
    bottom: 0.5rem;
    right: 0.9rem;
  }
}

.approved {
  color: green;

  &:hover {
    color: grey;
    font-size: 4rem !important;
    bottom: 0.5rem;
    right: 0.9rem;
  }
}


/* Customize the label (the checkbox) */
.checkbox {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding-left: 2rem;
  margin-bottom: 1rem;
  cursor: pointer;
  font-size: 1rem;
  font-weight: normal;
  text-transform: uppercase;
  color: var(--bg-color);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  /* Hide the browser's default checkbox */
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }


  /* When the checkbox is checked, add a blue background */
  input:checked~.checkmark {
    background-color: var(--bg-color);

    /* Show the checkmark when checked */
    &:after {
      display: block;
    }
  }

  /* On mouse-over, add a grey background color */
  &:hover input~.checkmark {
    background-color: #ccc;
    cursor: pointer;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: relative;
    margin-left: 0.3rem;
    height: 25px;
    width: 25px;
    border-radius: 3px;
    background-color: #eee;
    transition: all 0.3s;

    /* Create the checkmark/indicator (hidden when not checked) */
    &:after {
      content: "";
      position: absolute;
      display: none;

      /* Style the checkmark/indicator */
      left: 9px;
      top: 3px;
      width: 7.5px;
      height: 15px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
}