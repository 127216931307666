.testStoreValue {
  position: absolute;
  top: 0;
  right: 3rem;
  color: red;
  font-size: 1.5rem;
}

.minimized {
  background: rgba(255, 255, 255, 1);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);

  >div:first-child {
    display: none;
    visibility: hidden;
  }

  > :nth-child(3) {
    padding: 0.2rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    // background: yellow;

    ul {
      li {
        ul {
          top: 1.2rem !important;
        }

        a {
          padding: 0 0.5rem;
          font-size: 0.8em;
        }
      }
    }

    // transition: all 1.5s ease-out;
  }

  transition: all 1.5s ease-out;
}

.minimized .logo {
  opacity: 0;
  left: 0;
  transition: all 1s ease-out;
}

.scrolled .logo {
  opacity: 0;
  transition: all 1s ease-out;
}

.mobileMenu .logo {
  opacity: 0;
  transition: all 1s ease-out;
}

.list {
  li {
    display: block !important;
  }
}