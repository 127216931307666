.root {
  cursor: pointer;
  position: fixed;
  bottom: 0;
  margin: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  font-size: 3rem;
  color: var(--bg-color);
  &:hover {
    color: var(--bg-color-darker);
    opacity: 0.6;
  }
  transition: all 0.4s;
  opacity: 0.4;
}
