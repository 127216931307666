.root {
  background: white;
}

.formGroup {
  input {
    margin-bottom: 1rem;
    position: relative;
  }

  .label {
    position: absolute;
    top: -15px;
    left: 23px;
    color: #438ed8;
    z-index: 1;
    border-radius: 6px;
    padding-left: 5px;
    padding-right: 5px;
    background: #ffffff88;
    font-weight: 300;
  }
}

.message {
  position: relative;
  width: 80%;
  font-size: calc(2vw + 1rem);
  color: #4d4d4d;
  line-height: calc((2vw + 1rem) * 2);
  margin: 0 auto;
  top: calc(4vw + 1.5rem);
  padding: 2rem;
  padding-bottom: 6rem;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0 0px 40px #0005;
  z-index: 0;
  border-radius: 1rem;
  //   box-shadow: 0 20px 40px 20px rgba(255, 25, 255, 0.6);
}

.soon span {
  z-index: 1;
  line-height: 2rem;
  text-shadow: 3px 3px 0 #39a6e8;
}

.form {
  overflow: scroll;
  padding-top: 7rem;
  padding-bottom: 2rem;
  height: 400px;
}

.btn {
  &:disabled {
    background: grey;
    cursor: default;

    &:hover {
      background: grey;
    }
  }
}

.buttonGroup {
  display: flex;
  justify-content: center;
}

.fixedHeader {
  position: fixed;
  padding: 1rem;
  top: calc(2px + 1rem);
  width: calc(100% - 2rem - 4px);
  z-index: 2;
  text-align: center;
  margin: 0 auto;
  background: white;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.2);

  h2 {
    margin: 0;
    overflow: hidden;
  }
}