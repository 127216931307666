.root {
  position: relative;
  opacity: 0;
  transition: opacity 1.2s;
}

.actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 30rem;
}

.hide {
  opacity: 0;
}

.fadeIn {
  opacity: 1;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem;
  background: rgba(255, 255, 255, 0.6);

  h2 {
    max-width: 40rem;
    font-size: 5rem;
    line-height: 5rem;
    text-align: center;
    font-family: "Abhaya Libre", serif;
    color: var(--bg-color-darker);
    text-shadow: 0 0 1px black;
    margin-bottom: 1.5rem;
    margin-top: 5rem;

    .medium & {
      font-size: 3rem;
      margin-bottom: 1.5rem;
      line-height: 3.4rem;
    }

    .small & {
      font-size: 2.2rem;
      margin-bottom: 1rem;
      line-height: 2.6rem;
    }

    & strong {
      color: var(--text-color);
    }
  }
}