.fileUploadContainer {
  position: relative;
  margin: 35px 0 15px;
  border: 2px dotted lightgray;
  padding: 0.5rem;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
}

.articleSection {
  margin-top: 4rem;
  border-top: solid 1px grey;
  padding-top: 2rem;

  &:first-of-type {
    margin-top: 0;
    padding-top: 0;
    border-top: none;
  }
}

// FormField
.formField {
  cursor: pointer;
  font-size: 18px;
  display: block;
  width: 100%;
  border: none;
  text-transform: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;

  &:focus {
    outline: none;
  }
}

// InputLabel
.inputLabel {
  top: -21px;
  font-size: 13px;
  color: black;
  left: 0;
  position: absolute;
}

// DragDropText
.dragDropText {
  font-weight: bold;
  letter-spacing: 2.2px;
  margin-top: 0;
  text-align: center;
  color: grey;
}

// UploadFileBtn
.uploadFileBtn {
  box-sizing: border-box;
  appearance: none;
  background-color: transparent;
  border: 2px solid #3498db;
  cursor: pointer;
  font-size: 1rem;
  line-height: 1;
  padding: 1.1em 2.8em;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  border-radius: 6px;
  color: #3498db;
  position: relative;
  overflow: hidden;
  z-index: 1;
  transition: color 250ms ease-in-out;
  font-family: "Open Sans", sans-serif;
  width: 45%;
  display: flex;
  align-items: center;
  padding-right: 0;
  justify-content: center;

  &:after {
    content: "";
    position: absolute;
    display: block;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 100%;
    background: #3498db;
    z-index: -1;
    transition: width 250ms ease-in-out;
  }

  i {
    font-size: 22px;
    margin-right: 5px;
    border-right: 2px solid;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 20%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  @media only screen and (max-width: 500px) {
    width: 70%;
  }

  @media only screen and (max-width: 350px) {
    width: 100%;
  }

  &:hover {
    color: #fff;
    outline: 0;
    background: transparent;

    &:after {
      width: 110%;
    }
  }

  &:focus {
    outline: 0;
    background: transparent;
  }

  &:disabled {
    opacity: 0.4;
    filter: grayscale(100%);
    pointer-events: none;
  }
}

// FilePreviewContainer
article {
  margin-bottom: 35px;

  span {
    font-size: 14px;
  }
}

// PreviewList
.previewList {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  color: grey;

  @media only screen and (max-width: 400px) {
    flex-direction: column;
  }
}

// FileMetaData
.fileMetaData {
  //   display: ${(props) => (props.isImageFile ? "none" : "flex")};
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 10px;
  border-radius: 6px;
  color: white;
  font-weight: bold;
  background-color: rgba(0, 0, 0, 0.2);

  aside {
    margin-top: auto;
    display: flex;
    justify-content: space-between; // RemoveFileIcon

    .removeFileIcon {
      cursor: pointer;
      font-size: 2rem !important;
      transition: all 0.3s;

      &:hover {
        transform: scale(1.3);
        color: red;
      }
    }
  }
}

// PreviewContainer
.previewContainer {
  padding: 0.25rem;
  width: 20%;
  // height: 120px;
  border-radius: 6px;
  box-sizing: border-box;
  color: grey;

  &:hover {
    opacity: 0.55;

    // ${FileMetaData} {
    //   display: flex;
    // }
  }

  &>div:first-of-type {
    height: 100%;
    position: relative;
  }

  @media only screen and (max-width: 750px) {
    width: 25%;
  }

  @media only screen and (max-width: 500px) {
    width: 50%;
  }

  @media only screen and (max-width: 400px) {
    width: 100%;
    padding: 0 0 0.4em;
  }
}

// ImagePreview
img {
  border-radius: 6px;
  width: 100%;
  height: 100%;
}