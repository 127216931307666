.formContainer {
  // padding-top: 5rem;
  // z-index: 0;
}

.form {
  overflow: scroll;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.fixedHeader {
  // position: fixed;
  padding: 1rem;
  // top: calc(2px + 1rem);
  // left: calc(1rem + 2px);
  // width: calc(100% - 2rem - 4px);
  z-index: 2;
  // font-family: "Abhaya Libre", serif;
  // font-size: 4rem;
  text-align: center;
  margin: 0 auto;
  background: white;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.2);
  h2 {
    margin: 0;
  }
}

.buttonContainer {
  display: flex;
  justify-content: space-around;
  margin-bottom: 1rem;
}

.cancel {
  background: lightgrey !important;
  color: var(--bg-color) !important;
  &:hover {
    background: grey !important;
    color: white !important;
  }
}
