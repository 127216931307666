$button-size: 40px;

.root {
  cursor: pointer;
  z-index: 2;
  position: absolute;
  top: 0;
  right: 0;
  border: solid 2px grey;
  border-radius: $button-size / 1.5;
  background: yellow;
  &:hover {
    background: rgb(238, 238, 2);
  }
  transition: all 0.4s;
}

.modalType {
  background: white;
  border-color: var(--bg-color);
}

.closeButton {
  display: block;
  width: $button-size;
  height: $button-size;
  position: relative;
  overflow: hidden;
  > div {
    position: relative;
  }
  &Block {
    width: $button-size;
    height: $button-size / 2;
    position: relative;
    overflow: hidden;
    &:before,
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: calc(55% - 4px);
      display: block;
      width: $button-size / 10;
      height: $button-size / 1.6;
      transform-origin: bottom center;
      background: grey;
      transition: all ease-out 280ms;
    }
    &:last-of-type {
      transform: rotate(180deg);
    }
  }
  .in {
    .closeButtonBlock {
      &:before {
        transition-delay: 280ms;
        transform: translateX(20px) translateY(-20px) rotate(45deg);
      }
      &:after {
        transition-delay: 280ms;
        transform: translateX(-22px) translateY(-22px) rotate(-45deg);
      }
    }
  }
  .out {
    position: absolute;
    top: 0;
    left: 0;
    .closeButtonBlock {
      &:before {
        transform: translateX(-5px) translateY(5px) rotate(45deg);
      }
      &:after {
        transform: translateX(5px) translateY(5px) rotate(-45deg);
      }
    }
  }
  &:hover {
    .in {
      .closeButtonBlock {
        &:before {
          transform: translateX(-5px) translateY(5px) rotate(45deg);
        }
        &:after {
          transform: translateX(5px) translateY(5px) rotate(-45deg);
        }
      }
    }
    .out {
      .closeButtonBlock {
        &:before {
          transform: translateX(-20px) translateY(20px) rotate(45deg);
        }
        &:after {
          transform: translateX(20px) translateY(20px) rotate(-45deg);
        }
      }
    }
  }
}
.small {
  $button-size-small: $button-size / 2;

  .root {
    // background: transparent;
    // border: none;
    top: -10px;
    right: -10px;
    border-width: 1px;
    &:hover {
      background: #f7fdff;
    }
  }

  .closeButton {
    width: $button-size-small;
    height: $button-size-small;
    &Block {
      width: $button-size-small;
      height: $button-size-small / 2;
      &:before,
      &:after {
        width: $button-size-small / 10;
        height: $button-size-small / 1.6;
        left: calc(65% - 4px);
      }
    }
  }
}
