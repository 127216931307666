.js-scroll {
  transition: all 1.2s ease-in-out;
}

.slideUp {
  position: relative;
  opacity: 0;
  top: 5rem;
  &.js-scrolled {
    opacity: 1;
    top: 0;
  }
}

.slideRight {
  position: relative;
  opacity: 0;
  left: -25vw;
  &.js-scrolled {
    opacity: 1;
    left: 0;
  }
}

.slideLeft {
  position: relative;
  opacity: 0;
  right: -25vw;
  &.js-scrolled {
    opacity: 1;
    right: 0;
  }
}

.scaleUp {
  position: relative;
  opacity: 0;
  transform: scale(0.5);
  &.js-scrolled {
    opacity: 1;
    transform: scale(1);
  }
}
