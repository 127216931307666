.container {
  position: fixed;
  display: block;
  top: 12rem;
  left: 50%;
  transform: translateX(-50%);
  height: 100vh;
  max-height: 50vh;
  min-width: 20rem;
  max-width: 25rem;
  z-index: 0;
  cursor: zoom-in;
  // background: pink;

  &.normal {
    position: relative;
    top: 0;
    height: 40rem;
  }

  &.hide {
    display: none;
  }
}

.relative {
  position: relative;
  top: 0;
}

.imgWrapper {
  height: 100vh;
  max-height: 80vh;
  overflow: auto;
  background: #4a4a4a;

  img {
    object-fit: contain;
    max-height: 100%;
    max-width: 100%;
    vertical-align: middle;
  }
}

.root {
  overflow: hidden;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  // height: 100vh;
  max-height: 50vh;
  min-width: 20rem;
  max-width: 25rem;
  // box-shadow: inset 0 0 0 1px lightgray;

  &:before,
  &:after {
    position: absolute;
    width: 50%;
    height: 5.5%;
    // content: " ";
    left: 4.5%;
    bottom: 7%;
    transform: skew(-7deg) rotate(-2.75deg);

    box-shadow: 0 30px 2px 5px rgba(0, 0, 0, 0.3);
    z-index: -1;
  }

  &:after {
    left: auto;
    right: 15px;
    transform: skew(3deg) rotate(2.75deg);
  }

  .img {
    height: 100%;
    object-fit: contain;
  }

  .htmlNote {
    padding: 2rem;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
    background: url("/images/background/paper1.jpg");

    h1,
    h2,
    h3,
    h4 {
      font-size: 4rem;
      line-height: 5rem;
    }

    * {
      color: #4d4d4d;
    }

    p {
      font-size: 1.5rem;
      line-height: 2.25rem;
      font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
    }
  }

  &.enterFlyer {
    animation: paperUp 1s forwards ease-out;
    transition: all 2s cubic-bezier(0.5, 0.01, 1, 0.31);
  }

  &.initFlyer {
    animation: paperOut 1s forwards linear;
    transition: all 0s;
  }

  &.swingFlyer {
    animation: swinging 3.5s ease-in-out forwards infinite;
    transform-origin: 15% 17%;
    box-shadow: 0px 17px 6px -8px rgba(0, 0, 0, 0.2);
  }

  &.drop {
    animation: paperFall 1s forwards cubic-bezier(0.5, 0.01, 1, 0.31);
  }

  &.normal {
    position: relative;
    animation: none;
    top: 0;
    left: 0;
    transform: translateX(0);
    width: 100%;
    height: 100%;
  }
}

.thumbtackWrapper {
  position: absolute;
  top: 0;
  left: 50%;
  width: 4rem;
  cursor: pointer;
  transform-origin: 55px 80px;
  transform: translateX(-50vw);
  transition: all 0.3s ease-in-out;

  &.drop {
    transform: translateX(-50vw);
    transition: all 0.8s linear;
  }

  &.initPin {
    transform: translateX(-20vw);
    transition: all 2s ease-in;
  }

  &.enterPin,
  &.hoverOutPin {
    transform: translateX(0);
    transition: all 2s ease-in;
  }

  &:hover,
  &.hoverInPin {
    transform: translate(0, 0) scale(1.1);
    transition: all 0.3s ease-in-out;
  }

  &.hoverOutPin {
    transition: all 0.3s ease-in-out;
  }
}

.thumbtack {
  &.drop {
    transform: translateY(150vh) rotate(-1080deg);
    transition: all 0.8s cubic-bezier(0.76, 0.01, 1, 0.51);
  }

  &.initPin {
    transform: translateY(-80vh);
    transition: all 2s linear;
  }

  &.enterPin {
    transform: translateY(0);
    transition: all 2s ease-in;
  }
}

.clickMe {
  transition: all 0.8s cubic-bezier(0.76, 0.01, 1, 0.51);
  opacity: 0;
  transition: all 0.4s;
  width: 3rem;
  position: absolute;
  top: -2rem;
  left: 13rem;
  transform: rotate(225deg) scalex(-1);

  &.showClickMe {
    opacity: 0.5;
  }

  &.drop {
    opacity: 0;
  }

  img,
  >div {
    pointer-events: none;
    position: relative;
    animation: clickMe 1.5s ease-in-out alternate infinite;
    left: 0rem;
    top: -1rem;
  }

  transition: all 2s;
}

.curl {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(135deg,
      #ffffff,
      #f3f3f3 45%,
      #ddd 50%,
      #aaa 50%,
      #bbb 56%,
      #ccc 62%,
      #f3f3f3 80%,
      #fff 100%);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  transition: all 0.5s ease;

  &:before,
  &:after {
    // content: "";
    position: absolute;
    z-index: -1;
    left: 12.5%;
    bottom: 5.8%;
    width: 70%;
    max-width: 300px;
    max-height: 100px;
    height: 55%;
    box-shadow: 0 12px 15px rgba(0, 0, 0, 0.3);
    transform: skew(-10deg) rotate(-6deg);
  }

  &:after {
    left: auto;
    right: 5.8%;
    bottom: auto;
    top: 14.16%;
    transform: skew(-15deg) rotate(-84deg);
  }

  &:hover {
    width: 170px;
    height: 170px;
  }

  &:hover:before,
  &:hover:after {
    box-shadow: 0 24px 30px rgba(0, 0, 0, 0.3);
  }
}

@keyframes clickMe {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-0.6rem);
  }
}

@keyframes swinging {
  0% {
    transform: rotate(2.5deg) translate(-50%, 4rem);
  }

  50% {
    transform: rotate(-1deg) translate(-50%, 4rem);
  }

  100% {
    transform: rotate(2.5deg) translate(-50%, 4rem);
  }
}

@keyframes paperOut {
  0% {
    transform: translate(-50%, 100vh);
  }

  100% {
    transform: translate(-50%, 100vh);
  }
}

@keyframes paperUp {
  0% {
    transform: scale(1.3) translate(-50%, 150%);
  }

  100% {
    transform: translate(-50%, 4rem);
  }
}

@keyframes paperFall {
  0% {
    transform: translate(-50%, 4rem);
  }

  100% {
    transform: scale(1.3) translate(-50%, 150vh) rotateZ(15deg) rotateX(100deg) rotateY(120deg);
  }
}

@keyframes wiggle {

  0%,
  2% {
    transform: rotateZ(0);
  }

  4% {
    transform: rotateZ(-10deg);
  }

  5% {
    transform: rotateZ(7deg);
  }

  6% {
    transform: rotateZ(-7deg);
  }

  7% {
    transform: rotateZ(4deg);
  }

  8% {
    transform: rotateZ(-2deg);
  }

  10%,
  100% {
    transform: rotateZ(0);
  }
}