.classDetail {
  margin-bottom: 11rem;
}

.title {}

.small-form {
  overflow: scroll;
  padding-top: 6rem;
  padding-bottom: 2rem;
  height: 400px;
}

.textEditWrapper {
  position: relative;
  max-width: 30rem;
}

.textEdit {
  background: var(--bg-color-light);
  padding: 0.3rem 1rem;
  width: 100%;
}

.accordionLink {
  margin-left: 2rem;
  color: var(--text-color-sc);
  text-transform: uppercase;
  font-weight: 600;
  font-size: 0.8rem;
  cursor: pointer;

  &:hover {
    color: var(--bg-color-darker);
    // font-weight: 650;
    font-size: 0.825rem;
  }

  transition: all 0.4s;
}

.overlay {
  cursor: pointer;
  padding: 1rem;
  opacity: 0;
  background: #00000020;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  transition: all 0.4s;

  &:hover {
    opacity: 1;
  }
}

.reply {
  position: relative;
  display: inline-block;
  width: 100%;
}

.commentEdit {
  text-transform: uppercase;
  margin-left: 1rem;
  font-size: 0.8rem;
  font-weight: 600;

  &:hover,
  &:active {
    // color: #666;
    font-size: 1rem;
  }

  transition: all 0.3s;
  cursor: pointer;
}

.noForm {
  width: fit-content;
}

.vetting {
  background: transparent;
  display: flex;
  position: relative;
  justify-content: space-between;
  flex-direction: column;
  width: 8rem;
  max-width: 50%;
  height: 100%;
  backdrop-filter: blur(7px);

  .info,
  .danger,
  .safe {
    background: transparent;

    &:hover {
      color: white;
      background: initial;
      cursor: pointer;
    }

    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  }

  .safe {
    color: var(--safe-color);
    border: solid 1px var(--safe-color);

    &:hover {
      background: var(--safe-color);
    }
  }

  .danger {
    color: var(--danger-color);
    border: solid 1px var(--danger-color);

    &:hover {
      background: var(--danger-color);
    }
  }

  .info {
    color: var(--info-color);
    border: solid 1px var(--info-color);

    &:hover {
      background: var(--info-color);
    }
  }

  .disabled {
    opacity: 0.5;
    color: #fff;
    background: #999;
    border: none;

    &:hover {
      background: #d4d4d4;
      color: #888;
    }

    cursor: default !important;
    pointer-events: none !important;
  }

  .btn {
    background: transparent !important;
  }
}

.textarea,
.paragraph {
  font-size: 1.26rem;
  letter-spacing: -0.017em;
  padding: 0.5rem 1rem;
  line-height: 1.5;
  color: #686868;
  -webkit-font-smoothing: antialiased;
}

.textarea {
  width: 100%;
  padding-top: 1rem;
}

.paragraph {
  &:first-child {
    text-indent: 2.5rem;

    &::first-letter {
      font-size: 2rem;
      font-weight: 700;
      font-family: serif;
    }
  }

  margin: 1.3rem 4rem;
  padding: 0;
}

.blogText {
  position: relative;
  margin: 1.25rem 0;
  width: 100%;

  &:after {
    content: "";
    opacity: 0.6;
    overflow: hidden;
    font-size: 3rem;
    font-style: italic;
    font-family: fantasy;
    color: rgb(12, 58, 101);
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 90%;
    height: 4rem;
    bottom: -6rem;
    left: 50%;
    transform: translateX(-50%);
    background-image: url(../../../images/icons/blogend.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.save,
.titleSave {
  background: white;
  border: solid 1px var(--info-color);
  color: var(--info-color);

  &:hover {
    cursor: pointer;
    background: var(--info-color);
    color: white;
  }

  transition: all 0.3s;
  border-radius: 0.25rem;
  transform: translate(-50%, -50%);
  // transform: translateX(-50%);
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);

  &:disabled {
    color: grey;
    border-color: grey;
    box-shadow: none;

    &:hover {
      background: white;
      color: grey;
      cursor: default;
    }
  }
}

.save {
  position: fixed;
  z-index: 1;
}

.titleSave {
  position: relative;
}

.textWrap {
  position: relative;
  transform: translateX(0); // For relatively positoning fixed children
}

.titleInputWrapper {
  position: relative;
  font-size: 2rem;
  font-weight: 700;
  // width: 30rem;
}

.blogMedia {
  position: relative;
  cursor: pointer;
  font-size: 5rem;

  >div {
    background: rgba(255, 255, 255, 0.5);
  }

  &:hover {
    >div {
      opacity: 1;
      visibility: visible;
    }
  }
}