.root {
  height: 100%;
  object-fit: contain;
}

.notLoaded {
  display: none;
}

.spinner {
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  color: white;
}